/* eslint-disable camelcase */

//external
import { Navigate, Route, Routes } from 'react-router-dom';

// pages
import { AuthenticatedWrapper, RelationshipsWrapper } from 'components';
import { useMixpanelEvents } from 'hooks';
import {
  CompleteProfilePage,
  DashboardPage,
  ErrorPage,
  HelpPage,
  HomePage,
  LearningHubItemPage,
  LearningHubPage,
  LegalPageCookies,
  LegalPagePrivacy,
  LoginPage,
  LogoutPage,
  ProgramsListPage,
  SettingsAccountPage,
  SettingsAvailabilityPage,
  SettingsCommunicationPage,
  SettingsIntegrationsPage,
  SettingsPage,
  SettingsProfilePage,
  VideoPage,
  OTPSurveyPage,
  RegistrationPage,
  CreateProfilePage,
  ParticipationPage,
  GoalsPage,
  SkillsPage,
  BioPage,
  MatchesPage,
  GuideRegistrationPendingPage,
  GuideRegistrationPublishedPage,
  MatchingV2DemoPage,
  ProgramManageGuidePage,
  ProgramRegistrationAboutYouPage,
  ProgramRegistrationGoalsPage,
  ProgramRegistrationPage,
  ProgramRegistrationPreviewPage,
  TraineeRegistrationChoosePage,
  ConfigurePage,
  TeamsAuthPage,
} from 'pages';
import LegalPageTerms from 'pages/LegalPages/LegalPageTerms';
import { ProgramLandingPage } from 'pages/LandingPage';
import {
  ProgramGoalsPage,
  ProgramMatchesPage,
  ProgramMembershipPage,
  ProgramPreviewPage,
} from 'pages/ProgramsV2';
import { SettingsReviewsPage } from 'pages/SettingsPage/SettingsReviewsPage';
import { useOrganization } from '@guider-global/sanity-hooks';
import { getSubDomain } from '@guider-global/front-end-utils';

export const CustomRoutes = () => {
  useMixpanelEvents({ eventName: 'Page View' });

  const organizationSlug = getSubDomain();
  const { organization } = useOrganization({ organizationSlug });
  const enableProgramsV2 = organization?.programs?.enable_programsV2 ?? false;

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/configure" element={<ConfigurePage />} />
      <Route path="/teams-auth" element={<TeamsAuthPage />} />
      {/*  Authenticated Pages */}
      <Route
        path="/dashboard"
        element={<AuthenticatedWrapper element={<DashboardPage />} />}
      />

      {enableProgramsV2 ? (
        <Route path="/programs">
          <Route
            path=""
            element={<AuthenticatedWrapper element={<ProgramsListPage />} />}
          />
          <Route path=":programSlug">
            <Route path="" element={<ProgramLandingPage />} />
            <Route path="manage/:role" element={<ProgramMembershipPage />} />
            <Route path="join/:role">
              <Route path="" element={<ProgramMembershipPage />} />
              <Route path="goals" element={<ProgramGoalsPage />} />
              <Route path="preview" element={<ProgramPreviewPage />} />
              <Route path="choose" element={<ProgramMatchesPage />} />
            </Route>
            <Route path="*" element={<ErrorPage error={404} />} />
          </Route>
          <Route path="*" element={<ErrorPage error={404} />} />
        </Route>
      ) : (
        <Route path="/programs">
          <Route path=":programSlug">
            <Route path="manage/:role" element={<ProgramManageGuidePage />} />
            <Route
              path="matchingV2"
              element={
                <AuthenticatedWrapper element={<MatchingV2DemoPage />} />
              }
            />

            <Route path="join" element={<ProgramRegistrationPage />}>
              <Route path=":role">
                <Route
                  path="goals"
                  element={<ProgramRegistrationGoalsPage />}
                />
                <Route
                  path="preview"
                  element={<ProgramRegistrationPreviewPage />}
                />
                <Route
                  path="membership-pending"
                  element={<GuideRegistrationPendingPage />}
                />
                <Route
                  path="published"
                  element={<GuideRegistrationPublishedPage />}
                />

                <Route
                  path="choose"
                  element={<TraineeRegistrationChoosePage />}
                />
                <Route path="" element={<ProgramRegistrationAboutYouPage />} />
              </Route>
              <Route path="" element={<Navigate to="./programs" />} />
            </Route>
            <Route path="" element={<ProgramLandingPage />} />
          </Route>
          <Route
            path=""
            element={<AuthenticatedWrapper element={<ProgramsListPage />} />}
          />
          <Route path="*" element={<ErrorPage error={404} />} />
        </Route>
      )}

      <Route
        path="/relationships"
        element={<AuthenticatedWrapper element={<RelationshipsWrapper />} />}
      >
        <Route path=":relationshipId">
          <Route path="conclude" />
          <Route path="goals">
            <Route path="create" />
            <Route path=":goalId">
              <Route path="edit" />
            </Route>
          </Route>
          <Route path="notes">
            <Route path="create" />
            <Route path=":noteId">
              <Route path="edit" />
            </Route>
          </Route>
          <Route path="sessions">
            <Route path="create" />
            <Route path="log" />
            <Route path=":sessionId">
              <Route path="edit" />
            </Route>
          </Route>
        </Route>
      </Route>
      <Route path="/goals/*" element={<Navigate to="/relationships" />} />
      <Route
        path="/settings"
        element={<AuthenticatedWrapper element={<SettingsPage />} />}
      >
        <Route path="account" element={<SettingsAccountPage />} />
        <Route path="communication" element={<SettingsCommunicationPage />} />
        <Route path="profile" element={<SettingsProfilePage />} />
        <Route path="integrations" element={<SettingsIntegrationsPage />} />
        <Route path="availability" element={<SettingsAvailabilityPage />} />
        <Route path="reviews" element={<SettingsReviewsPage />} />
        <Route path="" element={<Navigate to="/settings/profile" />} />
        <Route path="*" element={<Navigate to="/settings/profile" />} />
      </Route>
      <Route
        path="/video/:id"
        element={<AuthenticatedWrapper element={<VideoPage />} />}
      />
      <Route
        path="/complete-profile"
        element={<AuthenticatedWrapper element={<CompleteProfilePage />} />}
      />

      <Route
        path="/learn"
        element={<AuthenticatedWrapper element={<LearningHubPage />} />}
      />
      <Route
        path="/learn/:itemSlug"
        element={<AuthenticatedWrapper element={<LearningHubItemPage />} />}
      />

      <Route path="/profile">
        <Route path="participation" element={<ParticipationPage />} />
        <Route path="goals" element={<GoalsPage />} />
        <Route path="skills">
          <Route path=":role" element={<SkillsPage />} />
        </Route>
        <Route path="bio" element={<BioPage />} />
        <Route path="matches">
          <Route path=":role" element={<MatchesPage />} />
        </Route>
      </Route>

      {/* Unauthenticated pages */}
      <Route path="/login" element={<LoginPage />} />
      <Route path="/register">
        <Route path="" element={<CreateProfilePage />} />
        <Route path="account" element={<RegistrationPage />} />
        <Route path="participation" element={<ParticipationPage />} />
        <Route path="goals" element={<GoalsPage />} />
        <Route path="skills">
          <Route path=":role" element={<SkillsPage />} />
        </Route>
        <Route path="bio" element={<BioPage />} />
        <Route path="matches">
          <Route path=":role" element={<MatchesPage />} />
        </Route>
      </Route>
      <Route path="/legal">
        <Route path="/legal/privacy" element={<LegalPagePrivacy />} />
        <Route path="/legal/terms" element={<LegalPageTerms />} />
        <Route path="/legal/cookies" element={<LegalPageCookies />} />
        <Route index path="/legal/*" element={<ErrorPage error={404} />} />
      </Route>

      <Route path="/help" element={<HelpPage />} />

      <Route path="/surveys/:id" element={<OTPSurveyPage />} />

      <Route path="/*" element={<ErrorPage error={404} />} />
      <Route path="/logout" element={<LogoutPage />} />
      <Route path="/error" element={<ErrorPage error={404} />} />
    </Routes>
  );
};

export default CustomRoutes;
